// eslint-disable
import React from 'react'
import Button from 'components/Button'
import { googleAnalyticsEvent } from 'helpers/googleTagManager';
import appConfig from '../../../assets/settings/appConfig.PRD-HDI.json'
import { TalkToUsSVG } from './assets/TalkToUs'

import * as S from './styles'

const eventTag: any = {
  btnDoubts: {
    button: 'Acessar ‘Dúvidas Frequentes’',
    event: 'home_btn_duvidas',
  },
  btnContact: {
    button: 'Fale Conosco',
    event: 'home_btn_faleconosco',
  }
}

const TalkToUs = () => {
  const sendEventData = (key: string) => {
    googleAnalyticsEvent({
      dataLayer: {
        location: 'Home',
        ...eventTag[key]
      },
    });
  }

  return (
    <S.Wrapper>
      <S.Container>
        <S.Title>
          Ainda tem dúvidas sobre o <br />
          seguro auto da {appConfig.COMPANY_NAME}?
        </S.Title>
        <S.Subtitle>
          Acesse nosso Dúvidas Frequentes ou fale diretamente
          com um corretor.
        </S.Subtitle>
        <S.Buttons>
          <Button theme="default" onClick={() =>{
            window.location.href = '/faq'
            sendEventData('btnDoubts')
          }}>
            Acessar ‘Dúvidas Frequentes’
          </Button>
          <Button
            theme="default"
            onClick={() => {
              window.location.href = '/contact?contact=1'
              sendEventData('btnContact')
            }}
          >
            Fale Conosco
          </Button>
        </S.Buttons>
      </S.Container>
      <S.ImageContainer>
        <TalkToUsSVG />
      </S.ImageContainer>
    </S.Wrapper>
  )
}

export default TalkToUs
